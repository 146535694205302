import { Container } from '@material-ui/core';
import React from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import './AboutUsPage.scss'
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

function AboutUsPage(){

    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "About Us",
          url: "https://www.simber.co.uk/about"
        },
    ]

    function jumpToContent() {
        var element = document.getElementsByClassName("first-paragraph")[0] as HTMLElement;
        window.scrollTo({behavior: "smooth", top: element.offsetTop})
    }
      
    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="About Simber Chauffeur Services" />
                <title>About Simber Chauffeur Services | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/about" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <div className="breadcrumbs"><StructuredBreadcrumb breadcrumbList={breadcrumbList}/></div>
                    <Container className="container about-text" maxWidth="lg">
                        <div className="about-wrapper service-picture"></div>
                        <div className="content about-content">
                            <h1 className="about-header"><span>About Us</span></h1>
                            <span className="jump-to-content-arrow" onClick={jumpToContent}></span>
                            <p className="first-paragraph">We are Simber, your choice of London Chauffeur Company, offering the ultimate discreet executive and VIP chauffeur experience for residential and international clients. We pride ourselves in being able to successfully plan and execute complex transportation requirements. As well as offering a tailored chauffeur service to suit our client’s needs, we offer a wide range of services that meet all your transportation needs.</p>
                            <p className='center b'><a className="primary-button" href="https://www.simber.co.uk">Book Now</a></p>
                            <p >As a company, Simber saw the light of day when partners, Andras, Denis & Norbert  joined forces to offer the travelers visiting London and the rest of the UK a new way of comfort and luxury during time of visit. Equipped with extensive experience, and the thirst for making a difference, we started a business that cares not only for our invaluable clients but also for our planet. We are eco-responsible and act. Simber offsets its carbon footprint by working together with Ecologi, our favorite climate change organization. We help fund the highest standard climate projects to accelerate our transition to a sustainable future by planting trees for every journey we undertake.</p>
                            <div className='divider'></div>
                            <p className="third-paragraph">We understand the importance of trust, discretion, safety, reliability and punctuality. Every client has different needs, we have a “can do” attitude, a wealth of experience in the industry and are confident in our ability to provide you with the right service solution. At SIMBER we strive for service excellence and our clients to date continue to choose us as their transportation partner for this very reason, they entrust us with their family, business associates, staff members and guests making sure they always receive an unparalleled service that is prompt, safe and discreet. </p>
                            <p>Our professional, attentive, polite and discreet chauffeurs combined with a premium fleet of cars, aim to provide you with the most comfortable, luxurious and efficient service regardless of the occasion. All of the services we offer and more have helped us grow our business through constant satisfactory recommendations from our loyal clients. </p>
                            <p className="last-paragraph">With a premium selection of vehicles and outstanding service by London's best chauffeurs, SIMBER is your choice of comfortable luxury.</p>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}

export default AboutUsPage;